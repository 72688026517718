import React, { useState, useEffect, lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Login from "./pages/Login/Login";
import { authHandler } from "./services/authHandler";

const AppRoutes = lazy(() => import("./AppRoutes"));

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticated = await authHandler.checkAuthentication();
      setIsLoggedIn(isAuthenticated);
    };
    checkAuthentication();
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <Router>
      {isLoggedIn ? (
        <Suspense fallback={<div>Loading...</div>}>
          <AppRoutes />
        </Suspense>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </Router>
  );
};

export default App;
