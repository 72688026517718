import axios from "axios";
import axiosInstance from "./axiosInstance";
export const authHandler = {
  isAuthenticated: false,
  userRole: null,
  intermediaryINFO: null,
  backendUrl:
    process.env.REACT_APP_BACKEND_URL ||
    "https://bamcbportal.azurewebsites.net/api",

  async login(username, password) {
    try {
      const response = await axios.post(this.backendUrl + "/auth/local", {
        identifier: username,
        password: password,
      });

      if (response.status === 200) {
        this.isAuthenticated = true;

        // Store JWT token and user role in local storage to keep the user logged in between page refreshes
        localStorage.setItem("username", username);
        localStorage.setItem("token", response.data.jwt);

        // Load User Data
        const userResponse = await this.getUserInfo();
        const user = userResponse.data;
        if (user.role) {
          this.userRole = user.role.name;
        }
        if (
          this.userRole === "Intermediary" ||
          (this.userRole === "Authenticated" && user.intermediary)
        ) {
          this.intermediaryINFO = {
            id: user.intermediary.id,
            name: user.intermediary.IntermediaryName,
          };
          localStorage.setItem(
            "userInfo",
            JSON.stringify(this.intermediaryINFO)
          );
        }

        localStorage.setItem("userRole", JSON.stringify(this.userRole));
        return response;
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  },

  logout() {
    this.isAuthenticated = false;
    this.userRole = null;
    //Remove JWT token and user role from local storage
    this.clearStorage();
    window.location.reload();
  },

  getUserRole() {
    const storedUserRole = localStorage.getItem("userRole");

    if (!storedUserRole || storedUserRole === "undefined") {
      return null;
    }

    return JSON.parse(storedUserRole);
  },

  getStoredUserInfo() {
    const storedUserInfo = localStorage.getItem("userInfo");
    return storedUserInfo ? JSON.parse(storedUserInfo) : null;
  },
  getUserID() {
    const user = this.getStoredUserInfo();
    return user.id;
  },

  async userDisplayName() {
    if (this.getUserRole() === "admin") {
      return "Central Bank";
    } else {
      const userResponse = await this.getUserInfo();
      return userResponse.data.intermediary.IntermediaryName;
    }
  },
  async getUserInfo() {
    try {
      const userResponse = await axiosInstance.get(
        this.backendUrl + "/users/me?populate=*"
      );
      return userResponse;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        return false;
      }
    }
  },
  async getUserInstance() {
    const userRole = this.getUserRole();
    if (userRole === "admin") {
      const storedInstance = localStorage.getItem("instance");
      return storedInstance ? storedInstance : "openCBDC";
    } else {
      const userResponse = await this.getUserInfo();
      return userResponse.data.intermediary.Instance;
    }
  },
  setInstance(instance) {
    localStorage.setItem("instance", instance);
  },
  async clearStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("userRole");
    localStorage.removeItem("username");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("instance");
  },
  async checkAuthentication() {
    const token = localStorage.getItem("token");
    const user = await this.getUserInfo();
    if (token && user) {
      this.isAuthenticated = true;
    } else {
      this.isAuthenticated = false;
      this.clearStorage();
    }
    return this.isAuthenticated;
  },
};
