// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
  width: max-content;
  margin: auto;
  padding: 60px 70px;
  box-shadow: 2px 2px 2px #ccc;
  margin-top: 20vh;
}
.login-icon {
  color: #bc262c;
  font-size: 24px;
}
.login-container ::placeholder {
  /* Most modern browsers support this */
  font-weight: bold;
  opacity: 1; /* Firefox needs this to work properly */
}

.login-form {
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 10px;
  width: 25vw;

  font-size: 20px;
}
.login-title {
  font-size: 30px;
  font-weight: bold;
  margin: auto;
  margin-bottom: 30px;
  color: #bc262c;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,4BAA4B;EAC5B,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,sCAAsC;EACtC,iBAAiB;EACjB,UAAU,EAAE,wCAAwC;AACtD;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;EAClB,WAAW;;EAEX,eAAe;AACjB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".login-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #f6f6f6;\n  width: max-content;\n  margin: auto;\n  padding: 60px 70px;\n  box-shadow: 2px 2px 2px #ccc;\n  margin-top: 20vh;\n}\n.login-icon {\n  color: #bc262c;\n  font-size: 24px;\n}\n.login-container ::placeholder {\n  /* Most modern browsers support this */\n  font-weight: bold;\n  opacity: 1; /* Firefox needs this to work properly */\n}\n\n.login-form {\n  display: grid;\n  grid-template-columns: auto;\n  grid-row-gap: 10px;\n  width: 25vw;\n\n  font-size: 20px;\n}\n.login-title {\n  font-size: 30px;\n  font-weight: bold;\n  margin: auto;\n  margin-bottom: 30px;\n  color: #bc262c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
