import React, { useState } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import { FaUser, FaLock } from "react-icons/fa";
import "./Login.css";
import { authHandler } from "../../services/authHandler";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null);

    if (!username || !password) {
      setErrorMessage("Username and password cannot be empty");
      return;
    }

    setIsLoading(true);
    try {
      const response = await authHandler.login(username, password);
      if (response.status === 200) {
        onLogin(response.data);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <Form className="login-form" onSubmit={handleSubmit}>
        <h1 className="login-title">Central Bank Portal</h1>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form.Group as={Row} controlId="username">
          <Form.Label column sm={2}>
            <FaUser className="login-icon" />
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Username"
              name="username"
              className=""
              value={username}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="password">
          <Form.Label column sm={2}>
            <FaLock className="login-icon" />
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          disabled={isLoading}
          style={{ borderRadius: "50px" }}
        >
          {isLoading ? "Loading..." : "Login"}
        </Button>
      </Form>
    </div>
  );
};

export default Login;
