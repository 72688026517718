// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
body {
  background: white;
}
.nav-link {
  color: black;
  padding: 20px !important;
  border-radius: 15px;
  background-color: #f8fafb !important;
  margin: 10px;
  display: block;
}

.nav-link:hover {
  background-color: lightgray !important;
}

.nav-link.active {
  color: white !important;
  background-color: #bc262c !important;
}
.main {
  margin-top: 20px;
  padding: 40px !important;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 20px;
  background-color: #a0070d;
  color: #fbad11;
  font-weight: bold;
}
.header a {
  text-decoration: none;
  color: #fbad11;
  font-weight: bold;
}
.modal-header {
  background-color: #a0070d;
  color: #fbad11;
  font-weight: bold;
}
.btn-close {
  color: white;
}
.flex-column {
  width: 70%;
}
.btn-primary {
  background-color: #bc262c !important;
  border: none !important;
  font-weight: bold !important;
}
.input-button {
  float: right;
  margin-bottom: "20px";
}

.shortLabel {
  width: 100px; /* Set the desired width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,wBAAwB;EACxB,mBAAmB;EACnB,oCAAoC;EACpC,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,uBAAuB;EACvB,oCAAoC;AACtC;AACA;EACE,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,yBAAyB;EACzB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,qBAAqB;EACrB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,4BAA4B;AAC9B;AACA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,YAAY,EAAE,0BAA0B;EACxC,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB","sourcesContent":["/* styles.css */\nbody {\n  background: white;\n}\n.nav-link {\n  color: black;\n  padding: 20px !important;\n  border-radius: 15px;\n  background-color: #f8fafb !important;\n  margin: 10px;\n  display: block;\n}\n\n.nav-link:hover {\n  background-color: lightgray !important;\n}\n\n.nav-link.active {\n  color: white !important;\n  background-color: #bc262c !important;\n}\n.main {\n  margin-top: 20px;\n  padding: 40px !important;\n}\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px;\n  font-size: 20px;\n  background-color: #a0070d;\n  color: #fbad11;\n  font-weight: bold;\n}\n.header a {\n  text-decoration: none;\n  color: #fbad11;\n  font-weight: bold;\n}\n.modal-header {\n  background-color: #a0070d;\n  color: #fbad11;\n  font-weight: bold;\n}\n.btn-close {\n  color: white;\n}\n.flex-column {\n  width: 70%;\n}\n.btn-primary {\n  background-color: #bc262c !important;\n  border: none !important;\n  font-weight: bold !important;\n}\n.input-button {\n  float: right;\n  margin-bottom: \"20px\";\n}\n\n.shortLabel {\n  width: 100px; /* Set the desired width */\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
